<template>
  <div class="">
    <div class="border-0 my-4">
      <div class="">
        <h4 class="m-0" :class="tag ? tag + ' text-primary h3' : 'text-dark'">
          Activities
        </h4>
        <ul
          class="nav border-bottom text-center"
          v-if="from_dashboard == false"
        >
          <li
            class="nav-item p-0 me-2"
            @click="pageChanger('your_ts')"
            :style="yourStyle"
          >
            <a
              class="nav-link px-0 py-2 fs-6"
              style="color: inherit; cursor: pointer"
              >Your Activities</a
            >
          </li>

          <li
            class="nav-item p-0"
            style="cursor: pointer"
            :style="teamStyle"
            @click="pageChanger('tm_ts')"
          >
            <a class="nav-link px-0 py-2 fs-6" style="color: inherit"
              >Team Member's Activities</a
            >
          </li>
        </ul>
        <!-- Your Timesheet page navigation -->
        <div
          class="m-0 p-0"
          v-if="current_page == 'your_ts' && from_dashboard == false"
        >
          <redmine-filter
            :page="redminePage"
            ref="redmineFilter"
            @redmine-filter-data="redmineFilterHandler"
            @reset-server-params="onResetServerParams"
            :serverParams="serverParamsfirst"
          ></redmine-filter>
          <!-- <ul class="nav nav-tabs">
                        <li class="nav-item new-font" style="width:8rem;">
                        <a class="nav-link border-0 text-center text-dark"
                        :class="current_option=='summary' ? 'active' : ''"
                        @click="optionChanger('summary')"
                        href="#">Summary</a>
                        </li>
                        <li class="nav-item new-font" style="width:8rem">
                        <a class="nav-link border-0 text-center text-dark"
                        :class="current_option=='attendance' ? 'active' : ''"
                        @click="optionChanger('attendance')"
                        href="#">Attendance</a>
                        </li>
                    </ul> -->
        </div>
        <!-- Your Timesheet page -->
        <div class="m-0 p-0 bg-white mt-2 p-3" v-if="current_page == 'your_ts'">
          <!-- User name -->
          <!-- <h2
            class="my-3"
            style="color: #171717"
            v-text="nameFormatter"
          ></h2> -->
          <!-- Report tables -->
          <div class="mt-2">
            <vue-good-table
              mode="remote"
              @on-sort-change="onSortChangefirst"
              @on-page-change="onPageChangefirst"
              @on-per-page-change="onPerPageChangefirst"
              :totalRows="TotalRecords1"
              :isLoading.sync="isLoadingfirsttable"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
              }"
              :rows="your_tasks_rows"
              :columns="your_tasks_column"
              styleClass="vgt-table no-border"
            >
              <!-- :scrollable="true" -->
              <template slot="table-row" scope="props">
                <span v-if="props.column.field == 'project_name'">
                  {{ props.row.project_name }}
                  <span
                    style="font-size: 12px"
                    class="d-block"
                    v-if="currentUser.user_role == 1"
                    >({{ props.row.client_name }})</span
                  >
                </span>
                <span v-else-if="props.column.field == 'description'">
                  <readMore
                    more-str="read more"
                    :text="props.row.description"
                    less-str="read less"
                    :max-chars="100"
                  >
                  </readMore>
                  <a
                    v-if="props.row.commit_link != null"
                    :href="props.row.commit_link"
                    target="_blank"
                    >commit link</a
                  >
                </span>
                <span v-else-if="props.column.field == 'short_desc'">
                  <readMore
                    more-str="read more"
                    :text="props.row.short_desc"
                    less-str="read less"
                    :max-chars="100"
                  >
                  </readMore>
                </span>
                <span v-else-if="props.column.label == 'Action'">
                  <div
                    class="d-flex justify-content-center align-items-center gap-3 p-2"
                  >
                    <button
                      v-if="permissions.includes('reports.update')"
                      class="btn-pencil"
                      @click="
                        triggerUpdateReportModal(
                          props.row.id,
                          props.row.tasks_id,
                          'not_approved'
                        )
                      "
                    >
                      <!-- <img src="../img/Group 2005.png" alt="edit" /> -->
                      <i class="fa fa-pencil fs-5"></i>
                    </button>
                    <button
                      @click="delete_report(props.row.id)"
                      class="btn-trash"
                      v-if="
                        permissions.includes('reports.delete') &&
                        props.row.created_by == currentUser.id &&
                        props.row.new_approved_time == null
                      "
                    >
                      <!-- <img src="../img/Group 2007.png" alt="edit" /> -->
                      <i class="fa fa-trash fs-5"></i>
                    </button>
                  </div>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template
                slot="column-filter"
                slot-scope="{ column, updateFilters }"
              >
                <span v-if="column.field == 'date'">
                  <datepicker
                    :clear-button="true"
                    :format="formateDate"
                    placeholder="yyyy-mm-dd"
                    @input="(e) => updateFilters(column, e)"
                  />
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="p-2">
                  <CustomPagination
                    :pageTabs="4"
                    :total="props.total"
                    :pageChanged="props.pageChanged"
                    :perPageChanged="props.perPageChanged"
                    :serverParams="serverParamsfirst"
                    v-if="your_tasks_rows.length !== 0"
                  />
                  <!-- Pdf and excel button for your reports -->
                  <!-- <button class="" v-for="item,index in props.total" :key="index">A</button> -->
                </div>
              </template>
            </vue-good-table>
            <div
              class="pt-3"
              v-if="from_dashboard == false && your_tasks_rows.length !== 0"
            >
              <button
                class="btn btn-primary me-2"
                @click="pdfDownload(header1)"
                v-if="permissions.includes('pdf.show')"
              >
                Pdf
              </button>
              <button
                class="btn btn-primary"
                @click="excelDownload(header1)"
                v-if="permissions.includes('excel.show')"
              >
                Excel
              </button>
            </div>
          </div>
        </div>
        <div v-if="current_page == 'tm_ts'">
          <redmine-filter
            :page="redminePage"
            @call-teammates-report="callTeammatesReportHandler"
            @redmine-filter-data="redmineFilterHandler"
            :serverParams="serverParams"
            :set_filters="snapshot_params"
          ></redmine-filter>
          <!-- Pdf and excel button -->
          <div
            class="d-flex justify-content-md-start justify-content-between gap-2 align-items-center"
            v-if="
              customData.length > 0 &&
              show_pdf_excel &&
              currentUser.user_role == 1
            "
          >
            <div
              class="form-check"
              v-if="
                permissions.includes('excel.show') &&
                permissions.includes('pdf.show')
              "
            >
              <input
                v-model="h_b_c"
                type="checkbox"
                :disabled="disabled"
                class="form-check-input"
                id="exampleCheck1"
              />
              <small
                ><label
                  class="form-check-label"
                  for="exampleCheck1"
                  style="padding-top: 0px !important"
                  ><b>Hours By Activity</b></label
                ></small
              >
            </div>
            <div
              class="form-check"
              v-if="
                permissions.includes('excel.show') &&
                permissions.includes('pdf.show')
              "
            >
              <input
                v-model="h_b_p"
                type="checkbox"
                :disabled="disabled"
                class="form-check-input"
                id="exampleCheck2"
              />
              <small
                ><label
                  class="form-check-label"
                  for="exampleCheck2"
                  style="padding-top: 0px !important"
                  ><b>Hours By Project</b></label
                ></small
              >
            </div>
          </div>
          <!-- && (selected_approve.status.selected_values[0].name=='Approved' || selected_approve.status.selected_values[0].name=='Both') -->
          <div
            class="d-flex justify-content-start gap-2 py-3 align-items-center flex-wrap"
            v-if="
              customData.length > 0 &&
              show_pdf_excel &&
              currentUser.user_role == 1
            "
          >
            <button
              class="btn btn-primary mb-2"
              style="width: 7rem"
              :disabled="disabled"
              @click.prevent="pdfDownload(header2)"
              v-if="permissions.includes('pdf.show')"
            >
              Pdf
            </button>
            <button
              class="btn btn-primary mb-2"
              style="width: 8rem"
              :disabled="disabled"
              @click.prevent="excelDownload(header2)"
              v-if="permissions.includes('excel.show')"
            >
              Merge Excel
            </button>
            <button
              class="btn btn-primary mb-2"
              :disabled="disabled"
              style="width: 7rem"
              @click.prevent="excelDownload(header3)"
              v-if="permissions.includes('excel.show')"
            >
              Excel
            </button>
          </div>
          <div
            class="bg-white rounded-2 p-2 shadow-sm"
            style="border-radius: 8px; top: 0"
            v-if="total_teammates_records > 100 && customData.length > 0"
          >
            <!-- <strong class="text-center">Total records:{{  total_teammates_records }}</strong> -->
            <CustomPagination
              :pageTabs="4"
              :total="total_teammates_records"
              :pageChanged="tmPageHandler"
              :perPageChanged="tmPerPageHandler"
              :serverParams="serverParams"
            />
          </div>
          <!-- Here is the spinner -->
          <div
            class="container-fluid m-0 p-5 mt-5 d-flex justify-content-center align-items-center"
            v-if="data_not_loaded"
          >
            <div
              class="spinner-border"
              role="status"
              style="height: 5rem; width: 5rem; color: #4e73df"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <!-- Loop table -->
          <div
            class="m-0 p-5 mt-5 d-flex justify-content-center align-items-center"
            v-if="data_load_msg"
          >
            <h3 v-text="data_load_msg"></h3>
          </div>
          <!-- No internet connection -->
          <div
            class="m-0 p-5 mt-5 d-flex justify-content-center align-items-center"
            v-if="no_internet"
          >
            <h3 v-text="no_internet"></h3>
          </div>
          <div
            v-if="
              data_not_loaded === false &&
              data_load_msg === '' &&
              no_internet === ''
            "
          >
            <!-- {{ custom_data }} -->
            <!-- Custom pagination component -->
            <!-- --------------------------- -->
            <div class="mb-3 p-0 mt-4" v-if="customData.length > 0">
              <div
                v-for="(s, index) of summary"
                class="d-flex justify-content-between align-items-center flex-wrap"
                style="border-radius: 8px"
                :key="index"
              >
                <span class="summary-font">
                  <b>Total Users:</b> {{ s.user_count }}
                </span>
                <span class="summary-font">
                  <b>Working Hours: </b>
                  {{
                    formatTimeStringFunction(
                      Math.floor(s.working_hours / 60),
                      s.working_hours % 60
                    )
                  }}
                </span>
                <span class="summary-font">
                  <b>Reported: </b>
                  {{
                    formatTimeStringFunction(
                      Math.floor(s.reported / 60),
                      s.reported % 60
                    )
                  }}
                </span>
                <span class="summary-font">
                  <b>Not Reported: </b>
                  {{
                    formatTimeStringFunction(
                      Math.floor(s.not_reported / 60),
                      s.not_reported % 60
                    )
                  }}
                </span>
                <span
                  v-if="
                    selected_approve.name == 'Approved' ||
                    selected_approve.name == 'Both'
                  "
                  class="summary-font"
                >
                  <b>Approved: </b>
                  {{
                    formatTimeStringFunction(
                      Math.floor(s.approved / 60),
                      s.approved % 60
                    )
                  }}
                </span>
                <span
                  v-if="
                    selected_approve.name == 'Not Approved' ||
                    selected_approve.name == 'Both'
                  "
                  class="summary-font"
                >
                  <b>Not Approved: </b>
                  {{
                    formatTimeStringFunction(
                      Math.floor(s.not_approved / 60),
                      s.not_approved % 60
                    )
                  }}
                </span>
                <span class="summary-font">
                  <b>Rejected: </b>
                  {{
                    formatTimeStringFunction(
                      Math.floor(s.rejected / 60),
                      s.rejected % 60
                    )
                  }}
                </span>
              </div>
            </div>
            <div v-if="showBulkApproveBtn">
              <button
                class="btn btn-primary btn-sm ms-auto d-block mb-3"
                @click="onBulkReportApprove"
              >
                Bulk Approve
              </button>
            </div>
            <div
              v-for="(user, index) of customData"
              :key="index"
              class="mb-2 overflow-hidden shadow-sm"
              style="border-radius: 6px 6px 0px 0px"
            >
              <div class="row py-1" style="background-color: #4e73df">
                <!-- 1 -->
                <div class="col-md-4 col-12 ps-4">
                  <span class="card-header-font">
                    {{ user.user_name }}
                    {{
                      "(" +
                      user.report_date +
                      " - " +
                      dayConverter(user.report_date) +
                      ")"
                    }}
                    -> {{ user.rt_name }}
                  </span>
                </div>
                <!-- 2 -->
                <div class="col-md-4 col-12 text-md-center ps-md-0 ps-4">
                  <span
                    v-text="'Total Spent time : ' + user.total_spent_time"
                    class="card-header-font p-0"
                  ></span>
                </div>
                <!-- 3 -->
                <div class="col-md-4 col-12 pe-md-4 text-md-end ps-md-0 ps-4">
                  <button
                    class="btn-report"
                    @click="approveReport(user.reports, 'approve_all')"
                    v-if="showApproveAll(user.reports)"
                  >
                    Approve All
                  </button>
                </div>
              </div>
              <div
                class="bg-white px-1 pb-1"
                style="overflow-x: auto"
                id="scroller"
              >
                <table class="table report-table">
                  <thead class="bg-light text-center">
                    <td style="width: 50px">Task Id</td>
                    <td style="width: 100px">Project</td>
                    <td style="width: 150px">Task</td>
                    <td style="width: 350px">Report</td>
                    <td style="width: 100px">Spent Time</td>
                    <td style="width: 120px">Approved Hours</td>
                    <td style="width: 60px">Action</td>
                    <td style="width: 120px">Category</td>
                    <td style="width: 100px">Approved By</td>
                  </thead>
                  <tbody class="bg-white text-center">
                    <tr v-for="(rep, idx) in user.reports" :key="idx">
                      <td v-text="rep.tasks_id"></td>
                      <td>
                        {{ rep.project_name }}
                        <span
                          style="font-size: 12px"
                          class="d-block"
                          v-if="currentUser.user_role == 1"
                        >
                          ({{ rep.client_name }} / {{ rep.subclient_name }})
                        </span>
                      </td>
                      <td>
                        <readMore
                          more-str="read more"
                          :text="rep.task_dec"
                          less-str="read less"
                          :max-chars="100"
                        >
                        </readMore>
                      </td>
                      <td>
                        <readMore
                          more-str="read more"
                          :text="rep.description"
                          less-str="read less"
                          :max-chars="100"
                        >
                        </readMore>
                        <a
                          v-if="rep.commit_link != null"
                          :href="rep.commit_link"
                          target="_blank"
                          >commit link</a
                        >
                      </td>
                      <td v-text="reported_hours(rep.new_spent_time)"></td>
                      <td
                        v-if="
                          !rep.is_approved &&
                          ((rep.approve_show &&
                            rep.user_id != currentUser.id) ||
                            currentUser.user_role == 1)
                        "
                      >
                        <my-time-picker-vue
                          :hours.sync="user.reports[idx]['spent_hours']"
                          :minutes.sync="user.reports[idx]['spent_minutes']"
                          :max_limit="rep.new_spent_time"
                          @update-time="
                            myTimePickerTimeUpdateHandler(
                              user.reports[idx],
                              $event
                            )
                          "
                        ></my-time-picker-vue>
                      </td>
                      <td v-else>
                        {{ reported_hours(rep.new_approved_time) }}
                      </td>
                      <td
                        v-if="
                          !rep.is_approved &&
                          ((rep.approve_show &&
                            rep.user_id != currentUser.id) ||
                            currentUser.user_role == 1)
                        "
                      >
                        <button
                          class="btn-view d-block mx-auto mb-1"
                          @click="
                            approveReport(
                              rep.id,
                              'approve',
                              user.reports[idx]['spent_hours'],
                              user.reports[idx]['spent_minutes']
                            )
                          "
                        >
                          <!-- <img
                            src="../img/Group 2005.svg"
                            style="width: 30px; height: 30px"
                            alt="reject"
                          /> -->
                          <i class="fa fa-check fs-5"></i>
                        </button>
                        <button
                          class="btn-pencil d-block mx-auto"
                          @click="
                            triggerUpdateReportModal(
                              rep.id,
                              rep.tasks_id,
                              'not_approved'
                            )
                          "
                          v-if="currentUser.user_role == 1"
                        >
                          <!-- <img
                            src="../img/Group 2005.png"
                            class="m-0"
                            alt="edit"
                            style="width: 30px; height: 30px"
                            /> -->
                          <i class="fa fa-pencil fs-5"></i>
                        </button>
                      </td>
                      <td v-else-if="rep.is_approved == true">
                        Approved<br />
                        <button
                          v-if="permissions.includes('approvedhours.update')"
                          :disabled="disabled"
                          class="btn btn-link p-0 m-0"
                          @click="
                            triggerUpdateReportModal(
                              rep.id,
                              rep.tasks_id,
                              'approved'
                            )
                          "
                        >
                          update
                        </button>
                        <button
                          :disabled="disabled"
                          class="btn btn-link p-0 m-0"
                          v-if="permissions.includes('approvedhours.delete')"
                          @click="delete_report(rep.id, 'tm')"
                        >
                          delete
                        </button>
                      </td>
                      <td v-else>Not Approved</td>
                      <td v-text="rep.report_category"></td>
                      <td
                        v-text="rep.approved_name ? rep.approved_name : '-'"
                      ></td>
                      <!-- <td v-text="rep.report"></td> -->
                      <!-- <td v-text="rep."></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <table v-show="false" id="testing-table">
          <div v-if="h_b_p">
            <!-- <div> -->
            <tr>
              <td colspan="2">Hours By Project</td>
              <!-- Uncomment this for allocated header in excel -->
              <!-- <td>Allocated</td> -->
            </tr>
            <tr v-for="(item, index) of sheet2_data3" :key="index">
              <td v-text="item.name"></td>
              <td v-text="item.formatString"></td>
              <!-- this field is for allocated hours just uncomment it and map data here -->
              <!-- <td v-text="item.allocated_hours"></td> -->
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
          </div>
          <div v-if="true">
            <tr>
              <td colspan="2">Hours By Resources</td>
              <!-- Uncomment this for allocated header in excel -->
              <!-- <td>Allocated</td> -->
            </tr>
            <tr v-for="item of sheet2_data2" :key="item.id">
              <td v-text="item.name"></td>
              <td v-text="item.formatString"></td>
              <!-- this field is for allocated hours just uncomment it and map data here -->
              <!-- <td v-text="item.allocated_hours"></td> -->
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <div v-if="h_b_c">
              <!-- <div> -->
              <tr>
                <td colspan="2">Activity</td>
              </tr>
              <tr
                v-for="(item, index) in sheet2_data1.table_data"
                :key="item.id + index"
              >
                <td v-text="item.name"></td>
                <td v-text="item.formatString"></td>
              </tr>
            </div>
          </div>
        </table>

        <!-- For update record -->
        <div
          class="modal fade"
          tabindex="-1"
          role="dialog"
          id="updateReportModal"
          aria-hidden="true"
          v-show="current_page == 'your_ts'"
        >
          <div
            class="modal-dialog modal-xl modal-dialog-centered"
            style="max-width: 80%"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="text-dark m-0" id="exampleModalLabel">
                  Update Report
                </h3>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body px-4">
                <update-report
                  :report_id="for_update_report.report_id"
                  :task_id="for_update_report.task_id"
                  v-if="Object.keys(for_update_report).length > 0"
                  :action="action"
                  @close-modal="closeModalHandler"
                ></update-report>
              </div>
            </div>
          </div>
        </div>

        <!-- update report approved hours pop up model end -->
        <div v-if="!permissions.includes('reports.read')">
          <UnauthorizedPage></UnauthorizedPage>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import user from "@/ajax/user";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import CustomPagination from "../../components/CustomPagination.vue";
// import { utils, writeFileXLSX, writeFile} from "xlsx-js-style";
import readMore from "vue-read-more";
import EventBus from "@/helpers/event-bus";
import myMixin from "@/mixins/Permission";
import TimePickerVue from "../../components/TimePicker.vue";
// import VueObserveVisibility from 'vue-observe-visibility';
// import PerfectScrollbar from 'vue2-perfect-scrollbar';
import updateReport from "./AddReports.vue";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { jsontoexcel } from "vue-table-to-excel";
import redmineFilter from "../../components/redmine_filter.vue";
import { utils, writeFileXLSX, writeFile } from "xlsx-js-style";

Vue.use(readMore);

export default {
  mixins: [myMixin],
  data() {
    return {
      siteLogReq: {
        site_name: "activities",
      },
      // To handle dashboard view
      from_dashboard: false,
      show_pdf_excel: false,
      for_update_report: {},
      disabled: false,
      h_b_p: true,
      h_b_c: true,
      update_approved_report_details: {},
      updated_approved_time: { hours: 0, minutes: 0 },
      updated_spent_time: { hours: 0, minutes: 0 },
      show_err_of_max_time: "",
      team_leads: [],
      selected_team_leads: [],
      current_page: "your_ts",
      current_option: "summary",
      users: [],
      active_users_list: [],
      projects: [],
      sub_clients: [],

      selected_sub_client: [],
      selected_users: [],
      selected_start_date: [],
      selected_projects: [],
      selected_approve: null,
      task_id: "",
      total_teammates_records: null,

      approve_dropdown: [
        { label: "Not Approved", name: "not_approved" },
        { label: "Approved", name: "aproved" },
        { label: "Both", name: "both" },
      ],

      serverParams: {
        filters: {},
        // columnFilters: {
        //   users: null,
        //   sub_clients: null,
        //   projects: null,
        //   start_date_from: null,
        //   start_date_to: null,
        //   approve: null,
        //   // flag: null,
        //   search_by_task_id: null,
        // },
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
      },

      warning: "",
      set_hours: { hours: 0, minutes: 0 },
      report_id: "",
      UserState: "",
      spent_hours: "",
      spent_minutes: "",
      reqObj: {
        description: "",
        spent_time: "",
        creater_name: "",
        date: "",
        project_name: "",
      },
      //For vue-good-table
      currentPage: "",
      totalRecordsfirsttable: 0,
      TotalRecords1: 0,
      isLoadingfirsttable: false,
      your_tasks_rows: [],
      serverParamsfirst: {
        filters: {},
        sort: [
          {
            field: "",
            type: "asc",
          },
        ],
        page: 1,
        perpage: 100,
        setcurrentPage: 1,
      },
      your_tasks_column: [
        {
          label: "Date",
          field: "date",
          filterable: true,
          sortable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "MM/DD/YYYY", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
          // Styling
          width: "128px",
          height: "43px",
        },
        {
          label: " Project",
          field: "project_name",
          filterable: true,
          thClass: "custom-th",
          tdClass: "custom-td",
          sortable: true,
          width: "191px",
          height: "43px",
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Project name", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Task",
          field: "short_desc",
          thClass: "custom-th",
          tdClass: "custom-td",
          width: "262px",
          height: "43px",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Task short summary", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Description",
          field: "description",
          thClass: "custom-th",
          tdClass: "custom-td",
          width: "422px",
          height: "43px",
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Report description", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Spent time",
          field: "new_spent_time",
          thClass: "custom-th",
          tdClass: "custom-td",
          width: "129px",
          height: "43px",
          formatFn: this.ConvertMinutesToTime,
          sortable: true,
          filterOptions: {
            enabled: false, // enable filter for this column
            placeholder: "Time spent in hours", // placeholder for filter input
            filterValue: "", // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.onColumnFilter, //custom filter function that
            trigger: "enter", //only trigger on enter not on keyup
          },
        },
        {
          label: "Approved Hours",
          field: "new_approved_time",
          // sortable:true,
          width: "144px",
          height: "43px",
          thClass: "custom-th",
          tdClass: "custom-td",
          formatFn: this.ConvertMinutesToTime,
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th",
          tdClass: "custom-td",
          width: "183px",
          height: "43px",
          // sortable: true,
        },
      ],
      custom_col: [
        {
          label: "Task Id",
          field: "tasks_id",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "50px",
          sortable: false,
        },
        {
          label: "Project",
          field: "project_name",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "150px",
          sortable: false,
        },
        {
          label: "Task",
          field: "task_dec",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "200px",
          sortable: false,
        },
        {
          label: "Report",
          field: "description",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "300px",
          sortable: false,
        },
        {
          label: "Spent time",
          field: "new_spent_time",
          formatFn: this.reported_hours,
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "136px",
          sortable: false,
        },
        {
          label: "Approved Hours",
          field: "approved_hours",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "50px",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "100px",
          sortable: false,
        },
        {
          label: "Category",
          field: "report_category",
          thClass: "custom-th2",
          tdClass: "custom-td",
          height: "43px",
          width: "120px",
          sortable: false,
        },
        {
          label: "Approved By",
          field: "approved_name",
          height: "43px",
          width: "120px",
          thClass: "custom-th2",
          tdClass: "custom-td",
          sortable: false,
        },
      ],
      // For first table pdf config
      header1: {
        name: "your_reports",
        thead: [
          "Date",
          "Project",
          "Task",
          "Spent time",
          "Description",
          "Approved Hours",
        ],
        body: [],
        // For second table pdf config
      },
      header2: {
        name: "team_mates_reports",
        thead: [
          "Date",
          "Name",
          "Project",
          "Activity",
          "Task",
          "Report",
          "Hours",
        ],
        body: [],
      },

      header3: {
        name: "test",
        thead: [
          "Date",
          "Name",
          "Project",
          "Activity",
          "Task",
          "Report",
          "Hours",
        ],
        body: [],
      },

      //For excel config
      excel: {
        data: [],
        head: "",
        filename: "",
      },
      permissions: window.localStorage.getItem("permissions"),
      time: { hours: "0", minutes: "0" },
      custom_data: [],
      temp_data: [],
      // key_ch: 1,
      sheet2_data1: [],
      sheet2_data2: [],
      sheet2_data3: [],
      categories: [],
      data_not_loaded: true,
      data_load_msg: "",
      no_internet: "",

      options: [],
      value: [],
      selected_start_date: [],
      task_id: "",
      permissions: localStorage.getItem("permissions"),
      config: {
        date: null,
        mode: "range",
        dateFormat: "Y-m-d",
        clickOpens: true,
        defaultDate: [
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      excel_download: null,
      action: "not_approved",
      approve: false,
      snapshot_params: null,
    };
  },
  provide() {
    return {
      data_not_loaded2: this.data_not_loaded,
      data_load_msg2: this.data_load_msg,
      no_internet2: this.no_internet,
      disabled2: this.disabled,
      summary2: this.summary,
      // getteamamtesreport:    this.$root.$emit('parentevent');
    };
  },
  components: {
    CustomPagination,
    updateReport,
    // ObserveVisibility,
    redmineFilter,
    TimePickerVue,
  },
  mounted() {
    this.setCurrentPage("Activities");
    this.getUserPermissions();

    if (this.$route.query.tab) {
      let tab = this.$route.query.tab;
      if (["your_ts", "tm_ts"].includes(tab)) {
        this.pageChanger(tab);
      }
    }

    EventBus.$on("dashboard-worklog", (params) => {
      // console.log("Dashboard called worklog ",params);
      this.from_dashboard = params;
      this.pageChanger("tm_ts");
    });
    this.snapshotParamsHandler();
    $("#updateReportModal").on("hidden.bs.modal", this.hideUpdateReportModal);
  },
  created() {
    user.createSiteUsage(this.siteLogReq);
  },
  methods: {
    ...mapActions("userStore", ["setCurrentUser", "logoutUser"]),
    ...mapActions("common", ["setCurrentPage"]),
    onBulkReportApprove() {
      Swal.fire({
        title: "Bulk Approve Reports",
        text: "Do you want to approve all reports?",
        icon: "question",
        showDenyButton: true,
        denyButtonText: `No`,
        confirmButtonText: "Yes",
        confirmButtonColor: "#32a852",
        denyButtonColor: "#f00",
        allowOutsideClick: false,
        icon: "question",
      }).then((result) => {
        if (result.value) {
          let bulkReports = this.customData
            .reduce((acc, cv) => {
              acc.push(...cv.reports);
              return acc;
            }, [])
            .filter((item) => item);
          this.approveReport(bulkReports, "approve_all");
        }
      });
    },
    onResetServerParams(serverParams) {
      this.serverParamsfirst = Object.assign(
        {},
        this.serverParams,
        serverParams
      );
      this.$nextTick(() => this.$refs.redmineFilter.applyFilter());
    },
    snapshotParamsHandler() {
      if (this.currentUser.user_role == 1) {
        let snapshot_params = JSON.parse(
          localStorage.getItem("snapshot-params")
        );
        if (snapshot_params) {
          let { user_id, date, teammates_page } = snapshot_params.shift();
          if (user_id && date && teammates_page) {
            this.pageChanger("tm_ts");
            this.snapshot_params = {
              user_id: user_id,
              date: date,
            };
            localStorage.setItem(
              "snapshot-params",
              JSON.stringify(snapshot_params)
            );
          }
        }
      }
    },
    myTimePickerTimeUpdateHandler(report, time) {
      report["spent_hours"] = time.hours;
      report["spent_minutes"] = time.minutes;
      // console.log(report,time)
    },
    callTeammatesReportHandler(params) {
      this.total_teammates_records = null;
      this.selected_approve = params.status.selected_values;
      // console.log("---------- ",this.selected_approve);
      this.show_pdf_excel = params.status.selected_values.name == "Approved";
      this.getteammatesreport();
    },
    tmPageHandler(params) {
      // console.log("tm page handler ",params);
      this.serverParams.page = params.currentPage;
      // this.serverParams.perpage=params.currentPerPage;
      // console.log(this.serverParams);
      this.getteammatesreport();
      // EventBus.$emit('parentEvent');
    },
    tmPerPageHandler(params) {
      // console.log("tm perpage handler ",params);
      this.serverParams.page = 1;
      this.serverParams.perpage = params.currentPerPage;
      // EventBus.$emit('parentEvent');
      this.getteammatesreport();
    },
    redmineFilterHandler(data, total) {
      if (this.redminePage == "tm_report") {
        // console.log(data);
        // console.log(total);
        // console.log("tm report handeled------------- ",data);
        this.custom_data = [];
        this.custom_data = data;
        // console.log("custom data",data);
      } else {
        // console.log("redmine your reports");
        this.your_tasks_rows = data;
        this.TotalRecords1 = total;
        // console.log(this.your_tasks_rows);
        // console.log(data);
        // console.log(total);
      }
    },
    showFilterDrawer() {
      $(".filter_bg_layer").fadeIn();
      $(".main_filter_wrapper").animate(
        {
          right: 0,
        },
        200,
        function () {}
      );
      $("body").attr("style", "overflow:hidden");
    },
    closeModalHandler() {
      $("#updateReportModal").modal("hide");
      // EventBus.$emit('parentEvent');
      if (this.current_page == "tm_ts") {
        this.getteammatesreport();
      } else {
        this.search_your_report();
      }
    },
    triggerUpdateReportModal(report_id, task_id, flag) {
      this.action = flag;
      this.for_update_report = Object.assign(
        {},
        { report_id: report_id, task_id: task_id }
      );
      $("#updateReportModal").modal("show");
    },
    hideUpdateReportModal() {
      this.for_update_report = {};
      // this.search_your_report();
    },
    changeapprovedhours(val) {
      this.show_err_of_max_time = "";
      this.update_approved_report_details = val;
      // console.log(this.update_approved_report_details)
      this.updated_approved_time.hours = (
        this.update_approved_report_details.new_approved_time / 60
      )
        .toString()
        .split(".")[0];
      this.updated_approved_time.minutes =
        this.update_approved_report_details.new_approved_time % 60;
      this.updated_spent_time.hours = (
        this.update_approved_report_details.new_spent_time / 60
      )
        .toString()
        .split(".")[0];
      this.updated_spent_time.minutes =
        this.update_approved_report_details.new_spent_time % 60;
      // this.$root.$emit("bv::show::modal", "report-update-model");
    },
    hideModal() {
      // this.$root.$emit("bv::hide::modal", "report-update-model", "#btnShow");
    },
    submitupdaterequest() {
      const send_details_for_update = {};
      // send_details_for_update
      send_details_for_update.new_approved_time =
        this.updated_approved_time.hours * 60 +
        parseInt(this.updated_approved_time.minutes);
      send_details_for_update.new_spent_time =
        this.updated_spent_time.hours * 60 +
        parseInt(this.updated_spent_time.minutes);
      send_details_for_update.user_id =
        this.update_approved_report_details.user_id;
      send_details_for_update.id = this.update_approved_report_details.id;
      if (
        this.updated_approved_time.hours * 60 +
          parseInt(this.updated_approved_time.minutes) >
        this.update_approved_report_details.new_spent_time
      ) {
        this.show_err_of_max_time = "** max limit is";
      } else {
        user
          .UpdateApprovedReportDetails(send_details_for_update)
          .then((result) => {
            if (result.data.code == 200) {
              this.hideModal();
              this.$swal(
                "Success",
                "Report approved time updated successfully...",
                "success"
              );
              // EventBus.$emit('parentevent','y_report');
              this.custom_data.length = 0;
              this.getteammatesreport();
            } else if (result.data.code == 404) {
              this.hideModal();
              this.$swal("Error", "Report not found...", "warning");
              // EventBus.$emit('parentevent','y_report');
              this.getteammatesreport();
            } else if (result.data.code == 401) {
              this.hideModal();
              this.$swal(
                "Error",
                "Not authorized for update approved hours...",
                "warning"
              );
              // EventBus.$emit('parentevent','y_report');
              this.getteammatesreport();
            } else {
              this.hideModal();
              this.$swal(
                "Error",
                "Report approved hours not updated...",
                "warning"
              );
            }
          });
      }
    },
    delete_report(id, table = null) {
      this.$swal({
        title: "Delete",
        text: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "",
        confirmButtonText: "Yes, Delete it!",
        closeOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$swal(
            "Deleted",
            "You successfully delete this report",
            "success"
          );
          user.delete_report(id).then((result) => {
            if (result && !table) {
              this.search_your_report();
              // EventBus.$emit('parentEvent','y_report');
            } else {
              this.OnApplyFilters();
            }
          });
        }
      });
    },
    formateDate(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    OnApplyFilters() {
      this.custom_data = [];
      // EventBus.$emit('parentEvent','y_report');
      this.getteammatesreport();
    },
    OnResetFilters() {
      this.custom_data = [];
      this.selected_projects = [];
      this.selected_users = [];
      this.selected_sub_client = [];
      this.team_leads = [];
      this.task_id = "";
      this.h_b_p = true;
      this.h_b_c = true;
      this.selected_team_leads = [];
      let date_array = [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      this.GetArrayOfStartDate(date_array);
      this.$refs.flatpickr.fp.setDate(date_array);
      // console.log(this.$refs.flatpickr);
      this.$root.$emit("parentevent");
    },
    returnTotalSpentTime(reports) {
      let hr = 0,
        min = 0;
      reports.forEach((obj) => {
        hr += obj.spent_hours;
        min += obj.spent_minutes;
      });
      if (min >= 60) {
        hr += Math.floor(min / 60);
        min = min % 60;
      }
      return this.formatTimeStringFunction(hr, min);
    },
    approveSpinner(data, feedback = null) {
      this.approve = false;
      if (!this.approve) {
        Swal.fire({
          html: `<div class='h-100 overflow-hidden p-5'>
                <div class='spinner-border text-primary' style='width:5rem;height:5rem'></div>
                <h3 class='mt-5'>Approving</h3>
              </div>`,
          allowOutsideClick: false,
          showConfirmButton: false,
        });
      }

      // Request Object
      let tempObj = {
        time_and_report_id: data,
      };
      feedback ? (tempObj.feedback = feedback) : "";

      user
        .ApproveReport(tempObj)
        .then((res) => {
          this.approve = true;
          if (res.data.code == 200) {
            this.approve = true;
            this.$swal({
              title: "Approved",
              text: "Report approved succesfully",
              icon: "success",
              timer: 2500,
            }).then(() => {
              this.custom_data = [];
              this.getteammatesreport();
            });
          } else if (result.data.code == 405) {
            this.$swal({
              title: "Not Allowed",
              text: "Report approve time is not allowed.....",
              icon: "error",
              timer: 2500,
            });
            this.getteammatesreport();
          } else {
            this.$swal("Not Valid", "Report not approved", "error");
          }
        })
        .catch((error) => {
          // console.log(error.code,error.response,error.message)
          this.approve = true;
          this.disabled = false;
          if (error.code == "ECONNABORTED") {
            this.$swal({
              title: "Slow Internet",
              text: "It takes too much time to respond",
              icon: "info",
              timer: 2000,
            });
          }
          // else if(error.message=="Network Error"){
          //     this.$swal({
          //     title: "Network Error",
          //     text: "No internet, Please check you internet connection",
          //     icon: "error",
          //     timer: 2000,
          //   });
          // }
          else if (
            error.code == "ECONNREFUSED" ||
            error.message == "Network Error"
          ) {
            this.$swal({
              title: "Error",
              text: "Something went wrong",
              icon: "error",
              timer: 2000,
            });
          } else {
            this.$swal({
              title: error.response.data.detail.title,
              text: error.response.data.detail.text,
              icon: "error",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              confirmButtonColor: "#32a852",
              cancelButtonColor: "#f00",
            }).then((err) => {
              if (err.value) {
                this.approveSpinner(data, err.value);
              }
            });
          }
        });

      // this.$swal({
      //   html: `<div class='h-100 overflow-hidden p-5'>
      //           <div class='spinner-border text-primary' style='width:5rem;height:5rem'></div>
      //           <h3 class='mt-5'>Approving</h3>
      //         </div>`,
      //   allowOutsideClick: false,
      //   showConfirmButton: false,
      //   didOpen: () => {
      //     const controller = new AbortController();
      //     let time = 0;
      //     let time_id = setInterval(() => {
      //       time += 1;
      //     }, 1000);
      //     setTimeout(() => {
      //       if (time >= 15) {
      //         clearInterval(time_id);
      //         this.disabled = false;
      //         this.$swal({
      //           title: "Slow Internet",
      //           text: "It takes too much time to respond",
      //           icon: "info",
      //           timer: 2000,
      //         });
      //       }
      //       if (navigator.onLine && time < 15) {
      //         user
      //           .ApproveReport(data, controller.signal)
      //           .then((result) => {
      //             if (result.data.code === 200) {
      //               this.$swal({
      //                 title: "Approved",
      //                 text: "Report approved succesfully",
      //                 icon: "success",
      //                 timer: 2500,
      //               }).then(() => {
      //                 this.custom_data = [];
      //                 this.getteammatesreport();
      //                 // EventBus.$emit('parentEvent','tm_report');
      //               });
      //             } else if (result.data.code == 405) {
      //               this.$swal({
      //                 title: "Not Allowed",
      //                 text: "Report approve time is not allowed.....",
      //                 icon: "error",
      //                 timer: 2500,
      //               });
      //               this.getteammatesreport();
      //               // EventBus.$emit('parentEvent','tm_report');
      //             } else {
      //               this.$swal("Not Valid", "Report not approved", "error");
      //             }
      //           })
      //           .catch((err) => {
      //             // console.log(err);
      //             if(err.code=='ECONNABORTED'){
      //               this.$swal({
      //                 title: err.response.data.detail.title,
      //                 text:err.response.data.detail.text,
      //                 icon: "error",
      //                 timer: 2500,
      //               });
      //               this.disabled = false;
      //             }
      //             else{
      //               if(err.code=='ECONNABORTED'){
      //               this.$swal({
      //                 text:"Something Went Wrong",
      //                 icon: "error",
      //                 timer: 2500,
      //               });
      //               this.disabled = false;
      //             }
      //           }
      //           });
      //       } else {
      //         if (time < 15) {
      //           controller.abort();
      //           this.$swal({
      //             title: "No internet Connection",
      //             text: "Please check your interenet connection",
      //             icon: "error",
      //             timer: 2500,
      //           });
      //           this.disabled = false;
      //         }
      //       }
      //     }, 1000);
      //   },
      // });
    },
    approveReport(id, type, hours, minutes) {
      let data = [];
      this.disabled = true;
      if (type == "approve") {
        data = [];

        // console.log("Original time",time)
        // console.log("Empty hours;",time.hours==="");
        // console.log("Empty minutes",time.minutes==="");
        // console.log("===if condition ",time.hours==="" || time.minutes==="")

        if (hours !== "" && minutes !== "") {
          if (hours == 0 && minutes == 0) {
            Swal.fire({
              title: "Do you want to approve 00:00 hours",
              icon: "question",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              confirmButtonColor: "#32a852",
              cancelButtonColor: "#f00",
            }).then((response) => {
              if (response.isConfirmed) {
                data.push({ id: id, time: { hours: hours, minutes: minutes } });
                this.approveSpinner(data);
              } else {
                this.disabled = false;
              }
            });
          } else {
            data.push({ id: id, time: { hours: hours, minutes: minutes } });
            this.approveSpinner(data);
          }
          // console.log("-----------",data);
          //The below function contain all code for ajax request with vuw seet alert on base of cases
        } else {
          this.$swal({
            title: "Not Allowed",
            text: "Approve time can not be blank",
            icon: "warning",
            timer: 2000,
          });
          this.disabled = false;
        }
      }

      if (type == "approve_all") {
        data = [];
        let show_modal = false,
          is_blank = false;
        for (let i of id) {
          if (i.is_approved == false && i.approve_show == true) {
            // console.log("time", i.time.hours, "minutes", i.time.minutes);
            if (i.spent_hours !== "" && i.spent_minutes !== "") {
              data.push({
                id: i.id,
                time: { hours: i.spent_hours, minutes: i.spent_minutes },
              });
              show_modal = false;
              is_blank = false;
            } else {
              show_modal = true;
              is_blank = true;
              break;
            }
          }
        }
        if (!show_modal) {
          // data.some(item=>{
          //   console.log(item);
          // })
          // console.log(data.some(item=>item.time.hours==0 && item.time.minutes==0));
          if (
            data.some((item) => item.time.hours == 0 && item.time.minutes == 0)
          ) {
            Swal.fire({
              title: "Do you want to approve 00:00 hours",
              icon: "question",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((response) => {
              if (response.isConfirmed) {
                this.approveSpinner(data);
              } else {
                this.disabled = false;
              }
            });
          } else {
            this.approveSpinner(data);
          }
        } else {
          this.disabled = false;
          if (!is_blank) {
            this.$root.$emit("bv::hide::modal", "modal-prevent-report");
            this.$swal("Error", "Report Not Approved...", "warning");
          } else {
            this.$root.$emit("bv::hide::modal", "modal-prevent-report");
            this.$swal(
              "Not Allowed",
              "Approve time can not be blank",
              "warning"
            );
          }
        }
      }
    },
    reported_hours(min) {
      if (min == null) {
        return "-";
      } else {
        var hours = "";

        var minutes = min;
        var hours = Math.floor(min / 60);
        minutes = min % 60;

        var minutes = minutes;

        var second = 0;

        var time =
          (hours >= 9 ? "" : "0") +
          hours +
          ":" +
          ((minutes >= 9 ? "" : "0") + minutes);
        return time;
      }
    },
    showApproveAll(data) {
      let show = false;
      let count = 0;
      for (let i of data) {
        if (
          i.is_approved == false &&
          i.approve_show == true &&
          (this.currentUser.id != i.user_id || this.currentUser.id == 1)
        ) {
          show = true;
          count++;
        }
      }
      return show;
    },
    dayConverter(date) {
      moment.locale("en");
      return `${moment(date).format("ddd")}`;
    },
    custom_label(name) {
      // console.log(this.users);
      if (name.last_name != null && name.middle_name != null) {
        return `${name.first_name} ${name.middle_name} ${name.last_name}`;
      } else if (name.last_name == null) {
        // console.log(name.first_name+name.middle_name);
        return `${name.first_name} ${name.middle_name}`;
      } else if (name.middle_name == null) {
        return `${name.first_name} ${name.last_name}`;
      }
    },
    custome_teams_label(name) {
      // console.log(this.users);
      if (name.id == this.currentUser.id) {
        return "My team";
      } else {
        if (name.last_name != null && name.middle_name != null) {
          return (
            `${name.first_name} ${name.last_name.charAt(0).toUpperCase()}` +
            "'s team"
          );
        } else if (name.last_name == null) {
          // console.log(name.first_name+name.middle_name);
          return `${name.first_name} ${name.middle_name}` + "'s team";
        } else if (name.middle_name == null) {
          return (
            `${name.first_name} ${name.last_name.charAt(0).toUpperCase()}` +
            "'s team"
          );
        }
      }
    },
    GetAllTeamLeadDropdowns() {
      user.GetAllTeamLeadDropdowns().then((result) => {
        if (result.data.code == 200) {
          this.team_leads = result.data.data;
        }
      });
    },
    formatTimeStringFunction(hours, minutes) {
      let formatString = "";
      if (hours < 10 && minutes < 10) {
        formatString = "0" + hours + ":0" + minutes;
        return formatString;
      } else if (hours < 10 && minutes > 10) {
        formatString = "0" + hours + ":" + minutes;
        return formatString;
      } else if (hours >= 10 && minutes < 10) {
        formatString = hours + ":0" + minutes;
        return formatString;
      } else {
        formatString = hours + ":" + minutes;
        return formatString;
      }
    },
    getActivitiesExcel(header) {
      let temp = JSON.parse(JSON.stringify(this.serverParams));
      this.excel_download = null;
      temp.perpage = -1;
      // console.log(temp,excel)
      if (header.name == "team_mates_reports" || header.name == "test") {
        // console.log(header.name);
        return user.getteammatesreportserverParams(temp).then((result) => {
          this.excel_download = result.data.data.data;
          // console.log("=============== ",this.excel_download,header.name);
          this.tableBodyMaker(header);
        });
      }
      if (header.name == "your_reports") {
        // console.log("your reports");
        return user.search_your_report(temp).then((result) => {
          this.excel_download = result.data.data.data;
          // console.log(excel_download);
          this.tableBodyMaker(header);
        });
      }
    },
    getteammatesreport() {
      this.disabled = true;
      // To abort api
      const controller = new AbortController();
      //navigator.onLine is used to check for internet connection
      EventBus.$on("offline", () => {
        this.data_not_loaded = false;
        this.disabled = false;
        this.no_internet = "No internet connection";
        return;
      });
      let interval_id = null;
      if (navigator.onLine) {
        // time count
        let time = 0;
        interval_id = setInterval(() => {
          if (this.data_not_loaded) {
            time++;
            if (time > process.env.VUE_APP_REQUEST_TIMEOUT) {
              // if(this.data_not_loaded){
              this.custom_data = [];
              this.summary = [];
              this.total_teammates_records = null;
              this.data_not_loaded = false;
              this.no_internet = "Slow internet connection";
              // }
              clearInterval(interval_id);
              // controller.abort();
            }
          } else {
            clearInterval(interval_id);
          }
        }, 1000);
        if (time <= process.env.VUE_APP_REQUEST_TIMEOUT) {
          user
            .getteammatesreportserverParams(
              this.serverParams,
              controller.signal
            )
            .then((result) => {
              // console.log("==========NewReport ",result.data.data);
              if (result.status === 200) {
                this.custom_data = result.data.data.data;
                this.summary = result.data.data.summary;
                this.total_teammates_records = result.data.data.total;
                // console.log("total_teamamtes_records",this.total_teammates_records);
                this.custom_data.forEach((item) => {
                  item.total_spent_time = this.returnTotalSpentTime(
                    item.reports
                  );
                });
                this.disabled = false;
                if (this.custom_data.length === 0) {
                  this.data_not_loaded = false;
                  this.data_load_msg = "No data available......";
                } else {
                  this.data_not_loaded = false;
                  this.data_load_msg = "";
                }
              }
            })
            .catch((err) => {
              this.disabled = false;
              this.data_not_loaded = true;
              let id = setTimeout(() => {
                controller.abort();
                this.data_not_loaded = false;
                this.no_internet = "Something went wrong....";
              }, 5000);
              clearTimeout(id);
            });
          this.data_not_loaded = true;
          this.data_load_msg = "";
          this.no_internet = "";
        }
      }
    },
    userListFilter() {
      let temp = [];
      if (this.users.length !== 0) {
        // console.log(this.users[0]['users_data'])
        this.users[0]["users_data"].forEach((item) => {
          // console.log("doe ",item.doe);
          if (item.doe !== null) {
            if (this.selected_start_date[0] <= item.doe) {
              temp.push(item);
            }
          } else {
            temp.push(item);
          }
        });
        // console.log(temp)
        this.active_users_list = [{ label: "Select all", users_data: temp }];
      }
    },
    getUsers() {
      user.getprojectandreportmeDropdown().then((result) => {
        // this.users = result.data.data;
        this.users = [{ label: "Select all", users_data: result.data.data }];
        this.userListFilter();
      });
    },
    getAllSubClient() {
      user.getAllSubClient().then((result) => {
        if (result.data.data[0]) {
          // this.sub_clients = result.data.data;
          this.sub_clients = [
            { label: "Select all", sub_clients_data: result.data.data },
          ];
          // this.reqObj.sub_client = result.data.data[(result.data.data).length-1]['id']
        }
      });
    },
    // get project list
    getAllSubclinetProjects() {
      user.getAllSubclinetProjects().then((result) => {
        // console.log("--- < > ",result.data.data)
        this.options = result.data.data;
        // this.options = [
        //   { label: "Select all", projects_data: result.data.data },
        // ];
      });
    },
    getAllProjects() {
      user.getAllProjects().then((result) => {
        // this.projects = result.data.data;
        this.projects = [
          { label: "Select all", projects_data: result.data.data },
        ];
      });
    },
    GetArrayOfStartDate(selectedDates, time, instance) {
      let dates = [];
      for (var i = 0; i < selectedDates.length; i++) {
        dates.push(moment(selectedDates[i]).format("YYYY-MM-DD"));
      }
      this.selected_start_date = dates;
      this.userListFilter();
    },
    pageChanger(page_name) {
      this.snapshot_params = null;
      if (this.current_page == page_name) return;
      if (this.disabled == false) {
        // console.log("in main if--------- ",this.page,page_name);
        this.custom_data.length = 0;
        this.your_tasks_rows.length = 0;
        this.current_page = page_name; //To pass current page selection in current_page variable

        // EventBus.$on("offline",()=>{
        //   return;
        // })

        // To call subclient api for populating subclients dropdown
        if (this.permissions.includes("sub_clients.read")) {
          // this.getAllSubClient();
        }

        // To call api for other dropdown population
        if (
          this.current_page === "tm_ts" &&
          this.permissions.includes("reports.read")
        ) {
          // let date1 = (this.serverParams.columnFilters["start_date_from"] = moment().startOf('month').format("YYYY-MM-DD"));
          // let date2 = (this.serverParams.columnFilters["start_date_to"] = moment().format("YYYY-MM-DD"));

          this.custom_data = [];
          this.serverParams.page = 1;
          this.serverParams.perpage = 100;
          // this.getteammatesreport();
          // this.selected_projects=[];
          // this.selected_users=[];
          // this.selected_sub_client=[];
          // this.team_leads=[];
          // this.task_id="";
          // this.h_b_p=true;
          // this.h_b_c=true;
          // this.selected_team_leads=[];
          // this.selected_approve=this.approve_dropdown[0];

          // this.getUsers();
          // this.GetArrayOfStartDate([date1, date2]);
          // this.getAllProjects();
          // this.GetAllTeamLeadDropdowns();
          // this.OnResetFilters();
          // this.OnApplyFilters();
        }
      }
    },
    optionChanger(option_name) {
      if (this.current_option !== option_name) {
        this.current_option = option_name;
      }
    },
    updateParamsfirst(newProps) {
      // console.log("new props",newProps)
      this.serverParams = Object.assign(this.serverParamsfirst, newProps);
      // console.log("updated server params ",this.serverParams);
    },
    onPageChangefirst(params) {
      // console.log("page change",this.serverParams,params);
      // console.log("2--- ",params);
      this.updateParamsfirst({ page: params.currentPage });
      this.search_your_report();
    },

    onPerPageChangefirst(params) {
      // To set the page default to 1 when perpage value changes
      this.updateParamsfirst({ perpage: params.currentPerPage, page: 1 });
      this.search_your_report();
    },

    onSortChangefirst(params) {
      // console.log("called")
      this.updateParamsfirst({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.search_your_report();
    },

    onColumnFilterfirst(params) {
      //remove empty fields
      // console.log("1")
      var newObj = { columnFilters: Object.assign({}) };
      Object.keys(params.columnFilters).forEach(function (key) {
        if (params.columnFilters[key] !== "")
          newObj.columnFilters[key] = params.columnFilters[key];
      });
      this.updateParamsfirst(newObj);
    },
    search_your_report() {
      // console.log(this.serverParamsfirst);
      var fields = {
        filters: this.serverParams.filters,
        // messageData: this.message,
        sort: this.serverParams.sort,
        page: this.serverParams.page,
        perpage: this.serverParams.perpage,
      };
      // console.log("search your report --------- ",fields);
      // user.search_your_report(fields).then((result) => {
      // this.your_tasks_rows = [];
      // this.your_tasks_rows = result.data.data.data;
      // this.TotalRecords1 = result.data.data.total;
      // console.log(result.data.data.data);
      // });
      this.$refs.redmineFilter.applyFilter();
    },

    // Logic to design excel sheet
    excelBgColor() {
      return {
        fgColor: { rgb: "d3d3d3" },
      };
    },
    excelFontSize() {
      return {
        bold: true,
      };
    },
    excelAlignment() {
      return {
        vertical: "top",
        horizontal: "left",
      };
    },
    excelAlignmentWithWrapText() {
      return {
        wrapText: true,
        vertical: "top",
        horizontal: "left",
      };
    },
    excelBorder() {
      return {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
      };
    },

    // Excel calculate Width
    calculateWidth(width_data, header_width_data) {
      if (width_data <= header_width_data.length) {
        return header_width_data.length;
      } else if (
        width_data > header_width_data.length &&
        width_data < header_width_data.length * 2
      ) {
        return width_data;
      } else if (width_data == header_width_data.length) {
        return width_data;
      } else {
        if (header_width_data == "Id") {
          return width_data;
        }
        return header_width_data.length * 2;
      }
    },
    pdfWidthSetter(body) {
      let result = [];
      body.forEach((item) => {
        let obj = {};
        item.forEach((subitem, index) => {
          obj[index] = subitem.length;
        });
        result.push(obj);
      });
      let width_measures = {};
      let key = 0;
      while (key <= body[0].length - 1) {
        let arr = [];
        result.forEach((item) => {
          arr.push(item[key]);
        });
        width_measures[key] = { cellWidth: Math.max(...arr) };
        key++;
      }
      // console.log(width_measures);
      return width_measures;
    },
    // Logic for pdf and excel report generation-------------------
    pdfDownload(header) {
      const doc = new jsPDF();
      this.disabled = true;
      if (header.name == "your_reports") {
        this.getActivitiesExcel(header).then((res) => {
          autoTable(doc, {
            head: [header.thead],
            body: header.body,
            styles: { fontSize: 8, halign: "center", valign: "middle" },
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 30 },
              3: { cellWidth: 20 },
              4: { cellWidth: 30 },
              5: { cellWidth: 50 },
              6: { cellWidth: 12 },
            },
          });
          doc.save(new Date().toISOString().substring(0, 10));
          this.disabled = false;
        });
      }
      if (header.name == "team_mates_reports") {
        this.getActivitiesExcel(header).then((res) => {
          this.getCategory();
          setTimeout(() => {
            // let width_measures=this.pdfWidthSetter(header.body)
            autoTable(doc, {
              head: [header.thead],
              body: header.body,
              styles: { fontSize: 8, halign: "center", valign: "middle" },
              columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 20 },
                2: { cellWidth: 30 },
                3: { cellWidth: 20 },
                4: { cellWidth: 30 },
                5: { cellWidth: 50 },
                6: { cellWidth: 12 },
              },
            });

            let new_body = [];
            // console.log("--------------------------------",this.sheet2_data1);
            if (this.categories.length !== 0) {
              this.sheet2_data1.table_data.map((item) => {
                new_body.push([item.name, item.formatString]);
              });
            }
            if (this.h_b_c) {
              autoTable(doc.addPage(), {
                head: [["Category", "hours"]],
                body: new_body,
              });
            }
            autoTable(doc, {
              body: this.sheet2_data2,
              columns: [
                { header: "User", dataKey: "name" },
                { header: "Spent hours", dataKey: "formatString" },
              ],
            });
            if (this.h_b_p) {
              autoTable(doc, {
                body: this.sheet2_data3,
                columns: [
                  { header: "Project", dataKey: "name" },
                  { header: "Spent hours", dataKey: "formatString" },
                ],
              });
            }
            // doc.addPage({html:});
            doc.save(new Date().toISOString().substring(0, 10));
            this.disabled = false;
          }, 500);
        });
      }
    },
    //table bod maker
    tableBodyMaker(header) {
      const arr = [];
      header.body = [];
      if (header.name == "your_reports") {
        this.excel_download.forEach((obj, index) => {
          arr.push([]);
          arr[index].push(obj.date);
          arr[index].push(obj.project_name);
          arr[index].push(obj.short_desc);
          arr[index].push(obj.spent_time);
          arr[index].push(obj.description);
          if (obj.approved_time == null) {
            arr[index].push("-");
          } else {
            arr[index].push(obj.approved_time);
          }
        });
        header.body = arr;
        // console.log(header.body);
      }
      if (header.name == "team_mates_reports") {
        header.body = [];
        let count = 0;
        // console.log(this.excel_download);
        this.excel_download.forEach((obj, index) => {
          arr.push([]);
          // console.log("----",obj)
          obj.reports.forEach((rep, idx) => {
            if (rep.is_approved && rep.new_approved_time > 0) {
              arr[index + count].push(obj.report_date);
              arr[index + count].push(obj.user_name);
              arr[index + count].push(rep.project_name);
              arr[index + count].push(rep.report_category);
              arr[index + count].push(rep.task_dec);
              arr[index + count].push(rep.description);
              //For spent time
              // let hour = rep.new_spent_time / 60;
              let hour = rep.new_approved_time / 60;
              let shour = Math.floor(hour);
              let min = Math.round((hour - shour) * 60);
              arr[index + count].push(shour + ":" + min);
              arr[index + count].push("Approved");
              arr[index + count].push(obj.client);
              arr[index + count].push(obj.subclient);
              arr[index + count].push(
                this.formatTimeStringFunction(shour, min)
              );
              arr.push([]);
              count++;
            }
          });
        });
        header.body = arr.filter((item) => {
          return item.length != 0;
        });
      }

      if (header.name == "test") {
        header.body = [];
        // console.log(this.custom_data);
        let count = 0;
        this.excel_download.forEach((obj, index) => {
          arr.push([]);
          // console.log(obj.reports)
          obj.reports.forEach((rep, idx) => {
            arr[index + count].push(obj.report_date);
            arr[index + count].push(obj.user_name);
            arr[index + count].push(rep.project_name);
            arr[index + count].push(rep.report_category);
            arr[index + count].push(rep.task_dec);
            arr[index + count].push(rep.description);
            //For spent time
            // let hour = rep.new_spent_time / 60;
            let hour = rep.new_approved_time / 60;
            let shour = Math.floor(hour);
            let min = Math.round((hour - shour) * 60);
            arr[index + count].push(this.formatTimeStringFunction(shour, min));
            if (rep.is_approved) {
              hour = rep.new_approved_time / 60;
              shour = Math.floor(hour);
              min = Math.round((hour - shour) * 60);
              arr[index + count].push(shour + ":" + min);
              arr[index + count].push("Approved");
            } else {
              arr[index + count].push("-");
              arr[index + count].push("Not Approved");
            }
            arr.push([]);
            count++;
          });
        });
        header.body = arr.filter((item) => {
          return item.length != 0;
        });
        // console.log(header.body);
      }
    },
    //Excel sheet generator
    excelDownload(header) {
      this.excel.data = [];
      let subclients = new Set();
      let clients = new Set();
      this.disabled = true;

      if (header.name == "your_reports") {
        this.getActivitiesExcel(header);
        // this.tableBodyMaker(header);

        header.body.forEach((obj) => {
          const data = {
            Date: obj[0],
            Project: obj[1],
            Task: obj[2],
            Category: obj[3],
            Description: obj[4],
            Hours: obj[5],
          };
          this.excel.data.push(data);
        });
        this.excel.head = header.thead;
        this.excel.filename = "Your Task Report";
        let wb = utils.book_new();
        let sheet = utils.json_to_sheet(this.excel.data);
        utils.book_append_sheet(wb, sheet, "All Users");
        writeFile(wb, this.excel.filename + ".xlsx");
        // const { data, head, filename } = this.excel;
        // jsontoexcel.getXlsx(data, head, filename);
        this.disabled = false;
      }

      if (header.name == "team_mates_reports") {
        // this.getteammatesreport();
        // if(this.excel_download==null){
        //   Swal.fire({
        //     html: `<div class='h-100 overflow-hidden p-5'>
        //         <div class='spinner-border text-primary' style='width:5rem;height:5rem'></div>
        //         <h3 class='mt-5'>Exporting Excel</h3>
        //       </div>`,
        //     allowOutsideClick: false,
        //     showConfirmButton: false,
        //   });
        // }
        this.getActivitiesExcel(header).then((res) => {
          this.getCategory();
          // this.tableBodyMaker(header);
          var arrForWidth = [];

          header.body.forEach((obj) => {
            function convertH2M(timeInHour) {
              var timeParts = timeInHour.split(":");
              var total_min = Number(timeParts[0]) * 60 + Number(timeParts[1]);

              if (Number.isInteger(total_min / 60)) {
                return total_min / 60;
              } else {
                // console.log(parseFloat((total_min / 60)).toFixed( 2 ));
                return parseFloat((total_min / 60).toFixed(2));
              }
            }
            // Changed index to 8 to solve issue for getting NaN in hours
            if (obj[8] != "00:00") {
              const data = [
                { v: obj[0], s: { alignment: this.excelAlignment() } },
                { v: obj[1], s: { alignment: this.excelAlignment() } },
                { v: obj[2], s: { alignment: this.excelAlignment() } },
                { v: obj[3], s: { alignment: this.excelAlignment() } },
                {
                  v: obj[4],
                  s: { alignment: this.excelAlignmentWithWrapText() },
                },
                {
                  v: obj[5],
                  s: { alignment: this.excelAlignmentWithWrapText() },
                },
                // Changed index to 8 to solve the issue of getting NaN in hours column of excel sheet(This issue arised due to addition of two columns in data namely clients,sublients)
                {
                  v: convertH2M(obj[6]),
                  s: { alignment: this.excelAlignment() },
                  t: "n",
                },
              ];
              arrForWidth.push({
                Date: obj[0],
                Name: obj[1],
                Project: obj[2],
                Category: obj[3],
                Description: obj[4],
                Report: obj[5],
                // Changed index to 8 to solve the issue of getting NaN in hours column of excel sheet(This issue arised due to addition of two columns in data namely clients,sublients)
                Hours: convertH2M(obj[6]),
              });
              this.excel.data.push(data);
            }
            clients.add(obj[8]);
            subclients.add(obj[9]);
          });

          // seperating data header wise and calculating final width

          const data_width = {
            dataDate_width: 13, //width of date will be same for all data
            dataName_width: this.calculateWidth(
              Math.max(
                ...arrForWidth
                  .map(function (el) {
                    return el.Name.trim();
                  })
                  .map((el) => el.length)
              ),
              header.thead[1]
            ),
            // this.calculateWidth(Math.max(...this.excel.data.map(function (el) { return el[1].trim(); })
            //                         .map(el => el.length)),header.thead[1]),
            dataProject_width: this.calculateWidth(
              Math.max(
                ...arrForWidth
                  .map(function (el) {
                    return el.Project.trim();
                  })
                  .map((el) => el.length)
              ),
              header.thead[2]
            ),
            dataCategory_width: this.calculateWidth(
              Math.max(
                ...arrForWidth
                  .map(function (el) {
                    return el.Category.trim();
                  })
                  .map((el) => el.length)
              ),
              header.thead[3]
            ),
            dataTask_width: 50, //max width is not fixed
            dataReport_width: 70, //max width is not fixed
            dataHours_width: 6, //width won't be greater than header name
          };

          this.excel.head = header.thead;

          // console.log(this.excel.head)
          // this.excel.filename = "Team Mates Task Report";

          // console.log(this.custom_data);
          // this.excelTable();

          setTimeout(() => {
            let ws = utils.json_to_sheet(this.excel.data);

            ws["!cols"] = [
              { width: data_width.dataDate_width },
              { width: data_width.dataName_width + 4 },
              { width: data_width.dataProject_width + 4 },
              { width: data_width.dataCategory_width + 4 },
              { width: data_width.dataTask_width },
              { width: data_width.dataReport_width },
              { width: data_width.dataHours_width },
            ];

            //adding css to all the header
            for (let i = 1; i <= header.thead.length; i++) {
              //console.log(`${String.fromCharCode(64+i)}1`)
              ws[`${String.fromCharCode(64 + i)}1`] = {
                v: header.thead[i - 1],
                s: {
                  font: this.excelFontSize(),
                  fill: this.excelBgColor(),
                  border: this.excelBorder(),
                },
              };
            }

            let tsheet = utils.table_to_sheet(
              document.getElementById("testing-table")
            );
            tsheet["!cols"].push({ width: 20 }, { width: 10 });
            // console.log(tsheet);

            const wb = utils.book_new();
            utils.book_append_sheet(wb, tsheet, "Summary");
            utils.book_append_sheet(wb, ws, "All Users");

            // writeFileXLSX(
            //   wb,
            //   new Date().toISOString().substring(0, 10) + ".xlsx"
            // );
            let filename = "";

            // if (subclients.size > 1 && clients.size > 1) {
            //   filename +=
            //     Array.from(clients).join(",").replace(/,/g, "&") + "_";
            //   filename += Array.from(subclients).join(",").replace(/,/g, "&");
            //   filename.length > 100
            //     ? (filename = filename.substring(0, 101))
            //     : "";
            // } else {
            //   filename = Array.from(clients) + "_" + Array.from(subclients);
            // }

            let final_date = [
              ...new Set(
                this.serverParams.filters.date.values.map((item) =>
                  moment(item).format("MMMYY")
                )
              ),
            ];

            if (this.serverParams.filters.date.values.length > 1) {
              const dateStr = final_date.join("/");
              filename += "Client-Logs-" + dateStr;
            } else {
              const dateStr = final_date.map((item) =>
                moment(item).format("MMMYY")
              );
              filename += "Client-Logs-" + dateStr;
            }
            writeFile(wb, filename + ".xlsx");
            this.disabled = false;
          }, 500);
        });
      }

      // users wish sheets
      if (header.name == "test") {
        // get category
        this.getActivitiesExcel(header).then((res) => {
          this.getCategory();
          setTimeout(() => {
            // groupby alias and report merge

            const group_users = this.groupBy(this.excel_download, "alias");

            // ordering for users
            var items1 = Object.keys(group_users).map(function (key) {
              return [key, group_users[key][0]["order"]];
            });
            items1.sort(function (first, second) {
              return first[1] - second[1];
            });

            const wb = utils.book_new();

            let tsheet = utils.table_to_sheet(
              document.getElementById("testing-table")
            );
            tsheet["!cols"].push({ width: 20 }, { width: 10 });

            // summary sheet
            utils.book_append_sheet(wb, tsheet, "Summary");

            // main loop
            let subclients = new Set();
            let clients = new Set();
            items1.forEach((obj, index) => {
              for (const user in group_users) {
                if (user == obj[0]) {
                  const arr = [];
                  const arrForWidth = [];
                  let w_hours = 0;

                  arr.push([
                    {
                      v: "Date",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Name",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Project",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Activity",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Id",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Task",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Report",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                    {
                      v: "Hours",
                      s: {
                        font: this.excelFontSize(),
                        fill: this.excelBgColor(),
                        border: this.excelBorder(),
                      },
                    },
                  ]);

                  group_users[user].forEach((obj, index) => {
                    subclients.add(obj.client);
                    clients.add(obj.subclient);

                    obj.reports.forEach((rep, idx) => {
                      let num1 = 0;
                      // let hour = rep.new_spent_time / 60;
                      let hour = rep.new_approved_time / 60;
                      let shour = Math.floor(hour);
                      let min = Math.round((hour - shour) * 60);
                      // if new_spent_time
                      // if (Number.isInteger(rep.new_spent_time / 60)) {
                      //   num1 =  rep.new_spent_time / 60;
                      // }
                      // else {
                      //   num1 = parseFloat(rep.new_spent_time / 60).toFixed( 2 );
                      // }

                      if (Number.isInteger(rep.new_approved_time / 60)) {
                        num1 = rep.new_approved_time / 60;
                      } else {
                        num1 = parseFloat(rep.new_approved_time / 60).toFixed(
                          2
                        );
                      }
                      if (rep.new_approved_time > 0) {
                        // arr.push([moment(obj.report_date).format('MM/DD/YYYY') ,obj.first_name ,rep.project_name ,rep.report_category ,rep.tasks_id ,rep.task_dec ,rep.description, num1])
                        arr.push([
                          {
                            v: obj.report_date,
                            s: { alignment: this.excelAlignment() },
                          },
                          {
                            v: obj.user_name,
                            s: { alignment: this.excelAlignment() },
                          },
                          {
                            v: rep.project_name,
                            s: { alignment: this.excelAlignment() },
                          },
                          {
                            v: rep.report_category,
                            s: { alignment: this.excelAlignment() },
                          },
                          {
                            v: rep.tasks_id,
                            s: { alignment: this.excelAlignment() },
                          },
                          {
                            v: rep.task_dec,
                            s: { alignment: this.excelAlignmentWithWrapText() },
                          },
                          {
                            v: rep.description,
                            s: { alignment: this.excelAlignmentWithWrapText() },
                          },
                          {
                            v: num1,
                            s: { alignment: this.excelAlignment() },
                            t: "n",
                          },
                        ]);
                        arrForWidth.push({
                          Date: obj.report_date,
                          Name: obj.user_name,
                          Project: rep.project_name,
                          Category: rep.report_category,
                          Id: rep.tasks_id,
                          Description: rep.task_dec,
                          Report: rep.description,
                          Hours: num1,
                        });
                        // w_hours = w_hours + rep.new_spent_time;
                        w_hours = w_hours + rep.new_approved_time;
                      }
                      // if (rep.is_approved) {
                      //   hour = rep.new_approved_time / 60;
                      //   shour = Math.floor(hour);
                      //   min = Math.round((hour - shour) * 60);
                      //   arr.push([obj.report_date ,obj.user_name ,rep.project_name ,rep.report_category ,rep.task_dec ,rep.description, this.formatTimeStringFunction(shour, min), (shour + ":" + min), "Approved"])
                      // }
                      // else {
                      //   arr.push([obj.report_date ,obj.user_name ,rep.project_name ,rep.report_category ,rep.task_dec ,rep.description, this.formatTimeStringFunction(shour, min), "-", "Not Approved"])
                      // }
                    });
                  });
                  // console.log("-------- ",arrForWidth);
                  var num = 0;
                  if (Number.isInteger(w_hours / 60)) {
                    num = w_hours / 60;
                  } else {
                    num = parseFloat(w_hours / 60).toFixed(2);
                  }

                  arr.push([
                    ,
                    ,
                    ,
                    ,
                    ,
                    ,
                    { v: "Total", s: { font: this.excelFontSize() } },
                    {
                      v: num,
                      s: {
                        font: this.excelFontSize(),
                        alignment: this.excelAlignment(),
                      },
                    },
                  ]);
                  // console.log("--- name and sum of hours",user, parseFloat(w_hours).toFixed( 2 ))

                  var ws2 = utils.aoa_to_sheet(arr);

                  const data_width = {
                    dataDate_width: 13, //width of date will be same for all data
                    dataName_width: this.calculateWidth(
                      Math.max(
                        ...arrForWidth
                          .map(function (el) {
                            return el.Name.trim();
                          })
                          .map((el) => el.length)
                      ),
                      header.thead[1]
                    ),
                    dataProject_width: this.calculateWidth(
                      Math.max(
                        ...arrForWidth
                          .map(function (el) {
                            return el.Project.trim();
                          })
                          .map((el) => el.length)
                      ),
                      header.thead[2]
                    ),
                    dataCategory_width: this.calculateWidth(
                      Math.max(
                        ...arrForWidth
                          .map(function (el) {
                            return el.Category.trim();
                          })
                          .map((el) => el.length)
                      ),
                      header.thead[3]
                    ),
                    dataId_width: this.calculateWidth(
                      Math.max(
                        ...arrForWidth
                          .map(function (el) {
                            return el.Id;
                          })
                          .map(String)
                          .map((el) => el.length)
                      ),
                      "Id"
                    ),

                    dataTask_width: 50, //max width is not fixed
                    dataReport_width: 70, //max width is not fixed
                    dataHours_width: 6, //width won't be greater than header name
                  };

                  ws2["!cols"] = [
                    { width: data_width.dataDate_width },
                    { width: data_width.dataName_width + 4 },
                    { width: data_width.dataProject_width + 4 },
                    { width: data_width.dataCategory_width + 4 },
                    { width: data_width.dataId_width + 4 },
                    { width: data_width.dataTask_width },
                    { width: data_width.dataReport_width },
                    { width: data_width.dataHours_width },
                  ];
                  ws2["!rows"] = {
                    s: {
                      alignment: { vertical: "center", horizontal: "center" },
                    },
                  };

                  if (num > 0) {
                    utils.book_append_sheet(wb, ws2, user + "(" + num + ")");
                  }
                }
              }
            });

            let filename = "";

            // if (subclients.size > 1 && clients.size > 1) {
            //   filename +=
            //     Array.from(clients).join(",").replace(/,/g, "&") + "_";
            //   filename += Array.from(subclients).join(",").replace(/,/g, "&");
            //   filename.length > 100
            //     ? (filename = filename.substring(0, 101))
            //     : "";
            // } else {
            //   filename = Array.from(clients) + "_" + Array.from(subclients);
            // }

            let final_date = [
              ...new Set(
                this.serverParams.filters.date.values.map((item) =>
                  moment(item).format("MMMYY")
                )
              ),
            ];

            if (this.serverParams.filters.date.values.length > 1) {
              const dateStr = final_date.join("/");
              filename += "Client-Logs-" + dateStr;
            } else {
              const dateStr = final_date.map((item) =>
                moment(item).format("MMMYY")
              );
              filename += "Client-Logs-" + dateStr;
            }
            writeFile(wb, filename + ".xlsx");
            // writeFile(wb, new Date().toISOString().substring(0, 10) + ".xlsx");
            this.disabled = false;
          }, 500);
        });
      }
    },

    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    getCategory() {
      user.getCategoryDropdown().then((result) => {
        this.categories = [];
        this.categories = result.data.data;
        this.excelTable();
      });
    },
    formatTimeStringFunction(hours, minutes) {
      let formatString = "";
      if (hours < 10 && minutes < 10) {
        formatString = "0" + hours + ":0" + minutes;
        return formatString;
      } else if (hours < 10 && minutes > 10) {
        formatString = "0" + hours + ":" + minutes;
        return formatString;
      } else if (hours >= 10 && minutes < 10) {
        formatString = hours + ":0" + minutes;
        return formatString;
      } else {
        formatString = hours + ":" + minutes;
        return formatString;
      }
    },
    excelTable() {
      let sheet2_data1 = {
        title: "Hours by category",
        table_data: [],
      };
      // For adding id,name of category,hours,allocated hours in the table_data array
      this.categories.forEach((item) => {
        sheet2_data1.table_data.push({
          id: item.id,
          name: item.name,
          hours: 0,
          minutes: 0,
          allocated: 0,
          formatString: "",
        });
      });
      // console.log("from excel----- ",this.categories);
      //for first table in sheet calulating hours according to category
      this.excel_download.forEach((obj) => {
        obj.reports.forEach((rep) => {
          sheet2_data1.table_data.forEach((item) => {
            if (item.id === rep.category_id) {
              // item.hours += Math.floor(rep.new_spent_time / 60);
              // item.minutes += rep.new_spent_time % 60;
              item.hours += Math.floor(rep.new_approved_time / 60);
              item.minutes += rep.new_approved_time % 60;
            }
          });
        });
      });

      // For summation of data below field is added
      sheet2_data1.table_data.push({
        id: 1001,
        name: "Total",
        hours: 0,
        minutes: 0,
        formatString: "",
      });

      let length = sheet2_data1.table_data.length;

      //Actual summation of data
      sheet2_data1.table_data.forEach((row, index) => {
        // console.log(row.hours);
        if (index < length - 1) {
          sheet2_data1.table_data[length - 1]["hours"] += row.hours;
          sheet2_data1.table_data[length - 1]["minutes"] += row.minutes;
        }
      });

      //Validating data accroding to hh:mm format
      sheet2_data1.table_data.forEach((item) => {
        if (item.minutes >= 60) {
          let additional_hr = Math.floor(item.minutes / 60);
          item.hours += additional_hr;
          item.minutes = item.minutes % 60;
        }
        let time = (item.minutes + item.hours * 60) / 60;
        // item.formatString = this.formatTimeStringFunction(
        //   item.hours,
        //   item.minutes
        // );
        item.formatString = Number.isInteger(time) ? time : time.toFixed(2);
      });
      // Filtering unfilled category

      //------------------------------------- id error issue
      let temp = [];
      temp = sheet2_data1.table_data.map((obj) => {
        // console.log(obj);
        if (
          (obj.hours != 0 && obj.minutes == 0) ||
          (obj.hours == 0 && obj.minutes != 0) ||
          (obj.hours != 0 && obj.minutes != 0)
        ) {
          return obj;
        }
        // return obj;
      });
      temp = temp.filter((obj) => obj);
      sheet2_data1.table_data = temp;
      // console.log("temp----- ",temp)
      this.sheet2_data1 = sheet2_data1;
      //for 2nd table in sheet

      let sheet2_data2 = {};
      this.excel_download.forEach((obj) => {
        if (Object.keys(sheet2_data2).length !== 0) {
          if (Object.keys(sheet2_data2).includes(obj.user_name)) {
            sheet2_data2[obj.user_name]["total_hours"].push(...obj.reports);
          } else {
            // sheet2_data2[obj.user_name]=obj.reports;
            sheet2_data2[obj.user_name] = {
              total_hours: [],
              allocated_hours: "",
              order: obj.order,
            };
            sheet2_data2[obj.user_name]["total_hours"].push(...obj.reports);
          }
        } else {
          //Just uncomment the below line to add allocated hours array
          // sheet2_data2[obj.user_name]={ total_hours:[],allocated_hours:[] };

          //Here allocated hours is for the fututre use just push the data in it using spread operator
          // sheet2_data2[obj.user_name]["allocated_hours"].push();

          sheet2_data2[obj.user_name] = {
            total_hours: [],
            allocated_hours: [],
            order: obj.order,
          };
          // this total hours contain the reports of the user irrespective to data from which total hours will be calcualted
          sheet2_data2[obj.user_name]["total_hours"].push(...obj.reports);
        }
      });

      //final calculated data for 2nd table in sheet

      this.sheet2_data2 = [];

      for (let user in sheet2_data2) {
        let sum = 0;
        let min = 0;
        let str = "";
        sheet2_data2[user].total_hours.forEach((rep) => {
          // sum += Math.floor(rep.new_spent_time / 60);
          // min += rep.new_spent_time % 60;
          sum += Math.floor(rep.new_approved_time / 60);
          min += rep.new_approved_time % 60;
        });
        if (min % 60 >= 0) {
          sum += Math.floor(min / 60);
          min = min % 60;
        }
        let time = (sum * 60 + min) / 60;
        //Just ucomment this line to map allocated hours
        // this.sheet2_data2.push({name:user,total_hours:sum,allocated_hours:null})
        // this.sheet2_data2.push({
        //   name: user,
        //   total_hours: sum,
        //   minutes: min,
        //   formatString: this.formatTimeStringFunction(sum, min),
        // });
        if (sum > 0 || min > 0) {
          this.sheet2_data2.push({
            name: user,
            total_hours: sum,
            minutes: min,
            order: sheet2_data2[user]["order"],
            formatString: Number.isInteger(time) ? time : time.toFixed(2),
          });
        }
      }
      //Logic for 2nd table total field
      this.sheet2_data2.push(
        this.sheet2_data1.table_data[this.sheet2_data1.table_data.length - 1]
      );
      let order_list = [];
      this.sheet2_data2.forEach((obj) => {
        if (!order_list.includes(obj.order) && obj.order !== undefined) {
          order_list.push(obj.order);
        }
      });
      this.sheet2_data2.sort((a, b) => {
        return a.order - b.order;
      });

      //For 3rd table of project

      let project_table = {};

      this.excel_download.forEach((obj) => {
        obj.reports.forEach((rep) => {
          if (Object.keys(project_table).length !== 0) {
            if (Object.keys(project_table).includes(rep.project_name)) {
              // console.log(rep.project_name)
              // console.log(Object.keys(project_table).includes(rep.project_name),rep.project_name);
              // project_table[rep.project_name].total_hours += rep.new_spent_time;
              project_table[rep.project_name].total_hours +=
                rep.new_approved_time;
              // console.log(project_table[rep.project_name]["total_hours"],rep.project_name);
            } else {
              project_table[rep.project_name] = { total_hours: 0 };
              // project_table[rep.project_name].total_hours += rep.new_spent_time;
              project_table[rep.project_name].total_hours +=
                rep.new_approved_time;
              // console.log(Object.keys(project_table).includes(rep.project_name),rep.project_name);
            }
          } else {
            project_table[rep.project_name] = { total_hours: 0 };
            // project_table[rep.project_name].total_hours += rep.new_spent_time;
            project_table[rep.project_name].total_hours +=
              rep.new_approved_time;
            // console.log(Object.keys(project_table).includes(rep.project_name),rep.project_name);
          }
        });
      });

      this.sheet2_data3 = [];

      for (let item in project_table) {
        let hr = Math.floor(project_table[item]["total_hours"] / 60);
        let min = project_table[item]["total_hours"] % 60;
        if (min % 60 >= 0) {
          hr += Math.floor(min / 60);
          min = min % 60;
        }
        // console.log(this.formatTimeStringFunction(hr,min),hr,min);
        let time = (hr * 60 + min) / 60;
        this.sheet2_data3.push({
          name: item,
          hours: hr,
          minutes: min,
          formatString: Number.isInteger(time) ? time : time.toFixed(2),
        });
      }
      this.sheet2_data3.push(
        this.sheet2_data1.table_data[this.sheet2_data1.table_data.length - 1]
      );
      this.sheet2_data3 = this.sheet2_data3.filter(
        (item) =>
          (item.minutes != 0 || item.hours != 0) &&
          Number.isInteger(item.minutes) &&
          Number.isInteger(item.hours)
      );
    },
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
    showBulkApproveBtn() {
      if (
        Object.keys(this.serverParams.filters).length > 0 &&
        this.currentUser.user_role == 1
      ) {
        const date_filter = this.serverParams.filters.date;
        const user_filter = this.serverParams.filters.users;
        const status_filter = this.serverParams.filters.status;
        if (date_filter && user_filter && status_filter) {
          const start_date = moment(date_filter.values[0]);
          const end_date = moment(date_filter.values[1]);
          const difference_days = end_date.diff(start_date, "days") + 1;
          const user_count = user_filter.values.length;
          const user_value =
            user_filter.values != null && user_filter.values.length != 0
              ? user_filter.values[0]
              : null;
          const user_option = user_filter.options;
          const status_option = status_filter.options;
          const status_value =
            status_filter.values != null && status_filter.values.length != 0
              ? status_filter.values[0]
              : null;
          return (
            difference_days <= 31 &&
            user_count == 1 &&
            user_value != this.currentUser.id &&
            user_option == "is" &&
            status_option == "is" &&
            status_value == "Not Approved"
          );
        }
      }
      return false;
    },
    customData() {
      return this.custom_data;
    },
    yourStyle() {
      // :style="{'color':current_page=='your_ts' ? '#583D72' : '#79869F','border-bottom': current_page=='your_ts' ? '3px solid #583D72' : ''}">
      if (this.current_page == "your_ts") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
    teamStyle() {
      // :style="{'color':current_page=='your_ts' ? '#583D72' : '#79869F','border-bottom': current_page=='your_ts' ? '3px solid #583D72' : ''}">
      if (this.current_page == "tm_ts") {
        return { color: "#583D72", "border-bottom": "3px solid #583D72" };
      } else {
        return { color: "#79869F", "border-bottom": "" };
      }
    },
    nameFormatter() {
      let str = "";
      if (this.currentUser.first_name) {
        str += this.currentUser.first_name;
      }
      if (this.currentUser.middle_name) {
        str += ` ${this.currentUser.middle_name}`;
      }
      if (this.currentUser.last_name) {
        str += ` ${this.currentUser.last_name}`;
        // return str;
      }
      return str;
    },
    redminePage() {
      return this.current_page == "tm_ts" ? "tm_report" : "y_report";
    },
  },
  props: ["tag"],
};
</script>

<style>
@font-face {
  font-family: "dm-sans";
  src: url("../fonts/DMSans-Regular.ttf");
}

.approve-btn:hover {
  /* border-bottom:1px solid white; */
  background: yellow;
}

.report-table {
  /* box-sizing:border-box; */
  table-layout: fixed;
  width: 100%;
}

.report-table thead td {
  padding: 2px 5px;
  border: 0px;
  border: 8px solid white;
  font-size: 16px;
  white-space: nowrap;
  word-wrap: break-word;
}

.report-table p {
  margin: 0px;
}

.report-table tbody td {
  padding: 5px 5px 5px 5px;
  /* min-height:20px!important; */
  word-wrap: break-word;
  text-align: center;
  vertical-align: middle;
  line-height: 1.2;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0.2px solid rgba(133, 135, 150, 0.2);
  color: rgb(105, 105, 105);
}

.btn-report {
  background: transparent;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: 0px;
  /* border-radius:8px; */
  /* border-color: transparent; */
}

.btn-report:hover {
  /* background:transparent; */
  color: whitesmoke;
  /* border-bottom:2px solid white; */
  /* transition:0.2s ease-out; */
  /* border-radius:8px; */
}

.new-font {
  font-size: 18px;
}

.report-font {
  font-size: 17px;
  color: #343434;
}

.summary-font {
  font-size: 17px;
  color: #343434d9;
}

.card-header-font {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.total-hours-font {
  font-size: 22px;
  font-weight: bold;
  color: white;
}

/* border-radius:8px; */
</style>
